import React, { useEffect, useState } from "react";
import { DataTable, type DataTableSortStatus } from "mantine-datatable";
import { type StudyAggregated } from "@/utils";
import { Chip, Flex, useMantineTheme } from "@mantine/core";
import { d } from "@/lib/core";
import { Text, Group, ActionIcon } from "@mantine/core";
import sortBy from "lodash/sortBy";
import { IconTrash } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import {
  type QueryObserverResult,
  type RefetchOptions,
  type RefetchQueryFilters,
  useMutation,
} from "@tanstack/react-query";
import { languageTag } from "@/paraglide/runtime";
import { deleteStudy as deleteStudyFn } from "@/utils/fetchers";
import * as m from "@/paraglide/messages";
import { queryClient } from "@/utils/client/queries/client";
import { USE_PRACTITIONER_PATIENTS_QUERY_KEY } from "@/utils/client/queries/keys";

type Props = {
  data: StudyAggregated[] | undefined;
  refetch?: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult> | null;
  isLoading: boolean;
  canDeleteStudy: (studyCreatedById: string) => boolean;
  hideActions?: boolean;
  currentPregnancyId?: string;
  isPregnancyFinished?: boolean;
};
function filterFinishedPregnancyRow(data: StudyAggregated[] | undefined) {
  if (!data) return;
  return data?.filter((study) => !study.data?.finishedPregnancy);
}

export function StudiesTable({
  data,
  refetch,
  isLoading,
  canDeleteStudy,
  hideActions = false,
  currentPregnancyId,
  isPregnancyFinished,
}: Props) {
  const lang = languageTag();
  const [records, setRecords] = useState(filterFinishedPregnancyRow(data));
  const theme = useMantineTheme();
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<StudyAggregated>
  >({
    columnAccessor: "date",
    direction: "desc",
  });

  const { mutate: deleteStudies, isLoading: isDeletingStudy } = useMutation({
    mutationFn: deleteStudyFn,
    mutationKey: ["delete-patient-study"],
    onSuccess: async () => {
      notifications.update({
        id: "delete-study",
        message: m.bland_bald_turtle_hike(),
        loading: false,
      });
      void queryClient.invalidateQueries({
        queryKey: [USE_PRACTITIONER_PATIENTS_QUERY_KEY],
      });
      if (refetch && typeof refetch === "function") await refetch();
    },
    onError: () => {
      notifications.update({
        id: "delete-study",
        message: m.kind_soft_ape_nail(),
        color: "red",
        loading: false,
      });
    },
  });

  useEffect(() => {
    setRecords(filterFinishedPregnancyRow(data));
  }, [data, isLoading]);

  useEffect(() => {
    const data = sortBy(records, sortStatus.columnAccessor);
    const filtered = filterFinishedPregnancyRow(data);

    setRecords(
      sortStatus.direction === "desc" && filtered
        ? filtered.reverse()
        : filtered
    );
  }, [sortStatus]);

  const columns = [
    {
      title: m.witty_curly_cockroach_quell(),
      accessor: "date",
      render: (study: StudyAggregated) => {
        const finished = study?.data?.finishedPregnancy;
        return study.date ? (
          <Flex gap="4px">
            <Text size="sm">
              {d(study.date).format(
                lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"
              )}
            </Text>
            {finished && (
              <Chip
                color="yellow"
                checked={true}
                variant="light"
                size="xs"
                styles={{
                  label: { padding: 4 },
                  iconWrapper: { display: "none" },
                }}
              >
                {m.trick_stout_crow_snip()}
              </Chip>
            )}
          </Flex>
        ) : null;
      },
      hidden: records?.every((study) => !study.date),
      sortable: true,
    },
    {
      title: m.wild_real_vulture_agree(),
      accessor: "obstetricWeeksGa",
      hidden: records?.every((study) => !study.obstetricWeeksGa),
    },
    {
      title: m.that_ornate_cow_build(),
      accessor: "fmfEFW",
      render: (study: StudyAggregated) => {
        return study.fmfEFW ? <Text size="sm">{study.fmfEFW}g</Text> : null;
      },
      hidden: records?.every((study) => !study.fmfEFW),
    },
    {
      title: m.broad_less_bee_hack(),
      accessor: "efwCentile",
      hidden: records?.every((study) => !study.efwCentile),
      cellsStyle: (study: StudyAggregated) => {
        if (study?.efwCentile)
          if (study?.efwCentile > 95 || study?.efwCentile < 5) {
            return { color: theme.colors.red[6] };
          }
      },
    },
    // {
    //   title: m.arable_weird_camel_pat(),
    //   accessor: "efwCentileFMF",
    //   hidden: records?.every((study) => !study.efwCentileFMF),
    //   cellsStyle: (study: StudyAggregated) => {
    //     if (study?.efwCentileFMF)
    //       if (study?.efwCentileFMF > 95 || study?.efwCentileFMF < 5) {
    //         return { color: theme.colors.red[6] };
    //       }
    //   },
    // },
    // {
    //   title: m.crisp_direct_snake_roam(),
    //   accessor: "efwCentileINTERGROUTH",
    //   hidden: records?.every((study) => !study.efwCentileINTERGROUTH),
    //   cellsStyle: (study: StudyAggregated) => {
    //     if (study?.efwCentileINTERGROUTH)
    //       if (
    //         study?.efwCentileINTERGROUTH > 95 ||
    //         study?.efwCentileINTERGROUTH < 5
    //       ) {
    //         return { color: theme.colors.red[6] };
    //       }
    //   },
    // },
    {
      title: m.mealy_strong_crow_link(),
      accessor: "pAUT",
      hidden: records?.every((study) => !study.pAUT),
      cellsStyle: (study: StudyAggregated) => {
        if (study?.pAUT && study?.pAUT > 95)
          return { color: theme.colors.red[6] };
      },
    },
    {
      title: m.next_quiet_tortoise_bubble(),
      accessor: "ipAUCentile",
      hidden: records?.every((study) => !study.ipAUCentile),
      cellsStyle: (study: StudyAggregated) => {
        if (study?.ipAUCentile && study?.ipAUCentile > 95)
          return { color: theme.colors.red[6] };
      },
    },
    {
      title: m.watery_less_shark_fall(),
      accessor: "ipACMP",
      hidden: records?.every((study) => !study.ipACMP),
      cellsStyle: (study: StudyAggregated) => {
        if (study?.ipACMP && study?.ipACMP < 5)
          return { color: theme.colors.red[6] };
      },
    },
    {
      title: m.helpful_just_wolf_assure(),
      accessor: "cerebroPlacentalRatioCentile",
      hidden: records?.every((study) => !study.cerebroPlacentalRatioCentile),
      cellsStyle: (study: StudyAggregated) => {
        if (
          study?.cerebroPlacentalRatioCentile &&
          study?.cerebroPlacentalRatioCentile < 5
        )
          return { color: theme.colors.red[6] };
      },
    },
    {
      title: m.wild_new_ray_foster(),
      accessor: "ipDVP",
      hidden: records?.every((study) => !study.ipDVP),
      cellsStyle: (study: StudyAggregated) => {
        if (study?.ipDVP && study?.ipDVP > 95)
          return { color: theme.colors.red[6] };
      },
    },
    {
      title: m.tasty_lower_wombat_spark(),
      accessor: "operator",
      hidden: records?.every((study) => !study.operator),
    },
    {
      accessor: "actions",
      title: "",
      hidden: hideActions,
      render: (study: StudyAggregated) => (
        <Group gap={4} wrap="nowrap">
          {/* TODO: Agregar vista para ver full estudio y editar? */}
          {/* <ActionIcon
                size="sm"
                variant="subtle"
                color="green"
                onClick={() => showModal({ company, action: "view" })}
              >
                <IconEye size={16} />
              </ActionIcon>
              <ActionIcon
                size="sm"
                variant="subtle"
                color="blue"
                onClick={() => showModal({ company, action: "edit" })}
              >
                <IconEdit size={16} />
              </ActionIcon> */}
          {!isPregnancyFinished && (
            // Avoid deleting study when preganancy end
            <ActionIcon
              size="sm"
              variant="subtle"
              color="red"
              onClick={() => {
                if (canDeleteStudy(study?.createdBy)) {
                  modals.openConfirmModal({
                    modalId: "deleteStudyModal",
                    title: m.strong_lucky_canary_stop(),
                    children: (
                      <Text size="sm">{m.sharp_elegant_llama_soar()}</Text>
                    ),
                    labels: {
                      confirm: m.confirm(),
                      cancel: m.cancel(),
                    },
                    // onCancel: () => console.log("Cancel"),
                    onConfirm: () => {
                      deleteStudies({
                        body: {
                          studies: [study.id],
                          deleteActivePregnancy: data?.length === 1, // borro el embarzo actual si es el último estudio
                          currentPregnancyId,
                        },
                        patientId: study.patientId!,
                      });
                      notifications.show({
                        id: "delete-study",
                        message: m.tangy_salty_canary_attend(),
                        loading: true,
                      });
                    },
                  });
                } else {
                  notifications.show({
                    message: m.many_real_antelope_dream(),
                  });
                }
              }}
            >
              <IconTrash size={16} />
            </ActionIcon>
          )}
        </Group>
      ),
    },
  ];
  return (
    <DataTable
      withTableBorder
      records={records}
      columns={columns}
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      fetching={isLoading || isDeletingStudy}
    />
  );
}
