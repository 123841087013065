import { Button, Loader, type ButtonProps } from "@mantine/core";
// import { IconCheck } from "@tabler/icons-react";
// import usePractitionerId from "@/hooks/usePractitionerId";
// import { usePractitioner } from "@/utils/client/queries/usePractitioner";
import { WeightCalculatorOptions } from "@/lib/db";
import usePractitionerPrefs from "@/hooks/usePractitionerPrefs";
// import { useMutation } from "@tanstack/react-query";
// import { updatePractitioner } from "@/utils/fetchers";
// import { notifications } from "@mantine/notifications";
// import usePractitionerId from "@/hooks/usePractitionerId";
// import { useEffect } from "react";

const providerLabel = (provider: string) => {
  switch (provider) {
    case WeightCalculatorOptions.fmf:
      return "FMF";
    case WeightCalculatorOptions.barcelona:
      return "FMBarcelona";

    default:
      return null;
  }
};

export const DopplerProvider = ({
  size = "xs",
  shouldTriggerPrefUpdate = false,
}: {
  size?: ButtonProps["size"];
  shouldTriggerPrefUpdate?: boolean;
}) => {
  // const practitionerId = usePractitionerId();
  const {
    isLoading,
    // weightPref,
    // refetch,
    setDopplerPref,
    dopplerProvider,
    // resetWeightPref,
  } = usePractitionerPrefs();
  // const { mutate, isLoading: updateLoading } = useMutation({
  //   mutationFn: updatePractitioner,
  //   mutationKey: ["update-practitioner"],
  //   onSuccess: async () => {
  //     notifications.show({
  //       message: "Preferencias actualizadas con éxito",
  //     });
  //     await refetch();
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //     notifications.show({
  //       message: "Error",
  //       color: "red",
  //     });
  //   },
  // });

  // useEffect(() => {
  //   return () => resetWeightPref();
  // }, []);

  const handlePrefChange = (provider: string) => {
    setDopplerPref(provider as WeightCalculatorOptions);
    // TODO: implementar db state para doppler pref
    // if (shouldTriggerPrefUpdate) {
    //   // TODO: HANDLE STORE
    //   // mutate({
    //   //   practitionerId,
    //   //   body: { weightProvider: provider },
    //   // });
    // } else {
    //   setDopplerPref(provider as WeightCalculatorOptions);
    // }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Button.Group>
          {[WeightCalculatorOptions.fmf, WeightCalculatorOptions.barcelona].map(
            (provider) => {
              const isActive = dopplerProvider.name === provider;
              return (
                <Button
                  radius="xl"
                  size={size}
                  key={String(provider)}
                  loaderProps={{ type: "dots" }}
                  variant={"default"}
                  onClick={() =>
                    !isActive ? handlePrefChange(provider) : null
                  }
                  // loading={updateLoading}
                  c={isActive ? "white" : "gray"}
                  bg={isActive ? "teal.8" : ""}
                >
                  {providerLabel(provider)}
                </Button>
              );
            }
          )}
        </Button.Group>
      )}
    </div>
  );
};
