import { useRouter, type NextRouter } from "next/router";
import { Breadcrumbs, Button, Text } from "@mantine/core";
import Link from "next/link";
import * as m from "@/paraglide/messages";
const buildRoutes = (router: NextRouter) => {
  const arr = [];

  if (router.pathname === "/app/patients") {
  }
  if (router.pathname === "/app/patients/[id]") {
    // arr.push({ title: "Calculadora", href: "/app" });
    arr.push({ title: m.large_equal_emu_dig(), href: "/app/patients" });
  }
  if (router.pathname === "/app/patients/new") {
    // arr.push({ title: "Calculadora", href: "/app" }); // no existe más
    arr.push({ title: m.large_equal_emu_dig(), href: "/app/patients" });
  }
  if (router.pathname === "/test-patient") {
    // arr.push({ title: "Calculadora", href: "/app" }); // no existe más
    arr.push({ title: m.large_equal_emu_dig(), href: "/app/patients" });
  }
  return arr;
};

export function Breadcrumb() {
  const router = useRouter();
  const items = buildRoutes(router);
  return (
    <Breadcrumbs
      separatorMargin="2px"
      separator={
        <Text c="gray.6" m="0">
          /
        </Text>
      }
    >
      {items.map((item, index) => (
        <Button
          c="gray.6"
          // size="compact-xs"
          variant="transparent"
          component={Link}
          // href={new URL(item.href, router.asPath)}
          href={{
            pathname: item.href,
          }}
          key={index}
          p="0"
        >
          {item.title}
        </Button>
      ))}
    </Breadcrumbs>
  );
}
